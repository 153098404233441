export const initialCoreDataState = {
    loading: false,
    collections: {              
        hire: null,
        hireLine: null  
    },

    errors: []
};

export const CoreDataReducer = (state, action) => {
  
    console.log('reducer about to attempt running action:', action)
    
    switch (action.type) {        

        case 'DATA_LOADING_STARTED':
            return {
                ...state,    
                collections: {hire: null, hireLine: null},
                loading: true,
                errors: []
        };

        case 'DATA_LOADING_FINISHED':
            return {
                ...state,    
                collections: {
                    hire: action.payload.hire, 
                    hireLine: action.payload.hireLine  
                },                
                loading: false,
                errors:  action.payload.errors               
        };
        
        default:
            return {                
                ...state,
                errors: "action.type not found"
            }; 
    }
};