import React, { useEffect, useContext } from 'react';
import history from '../utils/history';
import AuthContext from './authContext';


const AuthCheck = () => {
  const authContext = useContext(AuthContext)

  useEffect(() => {
    if(authContext.authObj.isAuthenticated()) {
      authContext.handleUserLogin()
      authContext.handleUserAddProfile(authContext.authObj.userProfile)
      history.replace('/')
    }
    else {
      authContext.handleUserLogout()
      authContext.handleUserRemoveProfile()
      history.replace('/')
      }
    }, [])

    return(
        <div>
        </div>
    )}




export default AuthCheck;