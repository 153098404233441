export const initialLookupDataState = {
    loading: false,
    collections: {              
        product: null,
        category: null,        
        warehouse: null,
        vehicle: null                 
    },

    errors: []
};

export const LookupDataReducer = (state, action) => {
  
    console.log('reducer about to attempt running action:', action)
    
    switch (action.type) {        

        case 'DATA_LOADING_STARTED':
            return {
                ...state,    
                collections: {product: null, category: null, warehouse: null, vehicle: null},
                loading: true,
                errors: []
        };

        case 'DATA_LOADING_FINISHED':
            return {
                ...state,    
                collections: {
                    product: action.payload.product, 
                    category: action.payload.category, 
                    warehouse: action.payload.warehouse, 
                    vehicle: action.payload.product},
                loading: false,
                errors:  action.payload.errors                 
        };
        
        default:
            return {                
                ...state,
                errors: "action.type not found"
            }; 
    }
};