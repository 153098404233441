import React, { useContext } from 'react';
import AuthContext from '../auth/authContext';


const Login = () => {

    const authContext = useContext(AuthContext)

    return(
        <div>         
          {!authContext.authState
            ? <div>Sorry, you must login to perform this action: <button onClick={() => authContext.authObj.login()}>Login</button></div>
            : <button onClick={() => authContext.authObj.logout()}>Logout</button>
          }
        </div>
    )      
}


export default (Login);