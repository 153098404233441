import {useContext, useEffect} from 'react';
import {LookupDataContext} from '../store/lookupDataContext';
import {AxiosGet} from '../utils/axiosGet';

const useRefreshLookupData = () => {

    const [ dataState, dispatch ] = useContext(LookupDataContext);
    const APIurl = process.env.REACT_APP_API_URL;

    useEffect(()=> {  
       
        const getData = async (url, dataType) => {
                        
            const result = await AxiosGet(url)
            
            if(result.error === null){

                console.log(dataType + ' payload data', result.data)
               
                return(result.data)
            }
            else
            {
                console.log(dataType + ' error', result.error)
                
                return({                  
                   'error': result.error.message
                })
            }           
        }

        const refresh = async() => {

            dispatch({
                type: 'DATA_LOADING_STARTED'                 
            });

            var results = await Promise.all([ getData(APIurl + 'Product', 'PRODUCT'), 
            getData(APIurl + 'Category', 'CATEGORY'),  
            getData(APIurl + 'Warehouse', 'WAREHOUSE'), 
            getData(APIurl + 'Vehicle', 'VEHICLE')]).then( (data) => {
                return {
                    'product': data[0],
                    'category': data[1],
                    'warehouse': data[2],
                    'vehicle': data[3]
                }
            });

            dispatch({
                type: 'DATA_LOADING_FINISHED', 
                payload: results
            });
        }
       
        console.log('About to refresh lookup data');
        refresh(); 
    }, []);

   return null;
}

export default useRefreshLookupData