import React, { useReducer } from 'react';
import Routes from './routes';

import {LookupDataContext} from './store/lookupDataContext';
import {CoreDataContext} from './store/coreDataContext';

import { LookupDataReducer, initialLookupDataState } from './store/lookupDataReducer';
import { CoreDataReducer, initialCoreDataState } from './store/coreDataReducer';

import AuthContext from './auth/authContext';
import {AuthReducer, initialUserState} from './auth/authReducer';
import * as USER_ACTIONS from './auth/authActions';

import Auth from './auth/auth';
const auth = new Auth()

const App = () => {
  
  console.log('App component running');
  console.log('Domain', process.env.REACT_APP_AUTH0_DOMAIN);

  const lookupDataState = useReducer(LookupDataReducer, initialLookupDataState);
  const coreDataState = useReducer(CoreDataReducer, initialCoreDataState);
  const [authState, authDispatch]  = useReducer(AuthReducer, initialUserState);

  const handleLogin = () => {
    authDispatch(USER_ACTIONS.login_success())
  }

  const handleLogout = () => {
    authDispatch(USER_ACTIONS.login_failure())
  }

  const handleAddProfile = (profile) => {
    authDispatch(USER_ACTIONS.add_profile(profile))
  }

  const handleRemoveProfile = () => {
    authDispatch(USER_ACTIONS.remove_profile())
  }

  //Handle authentication from callback
  const handleAuthentication = (props) => {    
    if(props.location.hash) {
      auth.handleAuth()
    }
  }
   
    return(
     
     <div>         
          <LookupDataContext.Provider value={lookupDataState}>   
            <CoreDataContext.Provider value={coreDataState}>
                <AuthContext.Provider  value={{
                      //Auth Reducer
                        authState: authState.is_authenticated,
                        profileState:  authState.profile,
                        handleUserLogin: () => handleLogin(),
                        handleUserLogout: () => handleLogout(),
                        handleUserAddProfile: (profile) => handleAddProfile(profile),
                        handleUserRemoveProfile: () => handleRemoveProfile(),

                        //Handle auth
                        handleAuth: (props) => handleAuthentication(props),
                        authObj: auth
                    }
                }>
                  <Routes />
                </AuthContext.Provider>
              </CoreDataContext.Provider>  
          </LookupDataContext.Provider>         
      </div>      
    )
}


export default App;