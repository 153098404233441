import React, { useContext } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router';
import { Link } from 'react-router-dom'
import history from './utils/history';
import AuthContext from './auth/authContext';
import AuthCheck from './auth/authcheck';
import Callback from './auth/callback';
import Login from './screens/login';
import Home from './screens/home';
import RequestHire from './screens/request-hire';
import Profile from './screens/profile';


const PrivateRoute = ({component: Component, auth }) => (
  <Route render={props => auth === true
    ? <Component auth={auth} {...props} />
    : <Redirect to={{pathname:'/login'}} />
  }
  />
)

const Routes = () => {
   
  const authContext = useContext(AuthContext)

      return(
        <div>         
          <Router history={history} >
        
          <div>
           <Link to='/'>Home</Link> 
           <br />
           <Link to='/request-hire'>Request hire</Link> 
           <br />
           <Link to='/profile'>Profile</Link> 
          
          </div>

            <Switch>
              <PrivateRoute exact path='/' auth={authContext.authState} component={Home} />
              <PrivateRoute path='/request-hire'  auth={authContext.authState} component={RequestHire} />
              <PrivateRoute path='/profile'  auth={authContext.authState} component={Profile} />             
                  
              <Route path='/login' component={Login} />     
              <Route path='/authcheck' component={AuthCheck} />           
              <Route path='/callback' render={(props) => { authContext.handleAuth(props);
                                                            return <Callback />}} />

            </Switch>         
          </Router>
        </div>
  )}



export default Routes;