import React, {useContext, useEffect, useState} from 'react';
import {LookupDataContext} from '../store/lookupDataContext';
import {CoreDataContext} from '../store/coreDataContext';
import useRefreshLookupData from '../hooks/useRefreshLookupData';
import useRefreshCoreData from '../hooks/useRefreshCoreData';

const Home = () => {

    console.log('i am the home component');
   
    const [ lookupDataState, lookupDispatch ] = useContext(LookupDataContext);
    const [ coreDataState, coreDispatch ] = useContext(CoreDataContext);
 
    useRefreshLookupData();
    useRefreshCoreData();
    
    console.log('i am a render in home');

    return (
        lookupDataState.loading || coreDataState.loading ? <div>Loading</div> : 
        <div>
            {JSON.stringify(lookupDataState)}  
            hello
        </div>
    )
}

export default Home;