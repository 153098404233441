import React, { useContext } from 'react';
import AuthContext from '../auth/authContext';


const Profile = () => {

  const authContext = useContext(AuthContext)

  console.log('auth context', authContext);

  const RenderProfile = (props) => {

    console.log('profile', props.profile);

    return(
      <div>
        <h1>Id token for: {props.profile.nickname}</h1>
        <br />       
        {JSON.stringify(props.profile)}
        <br />
      </div>
     )
   }


    return(
      <div>
        <RenderProfile profile={authContext.authObj.userProfile} />
      </div>
  )}



export default (Profile);